import {API, Storage} from "aws-amplify";
import {v4 as uuid4} from "uuid";

export async function s3Upload(file, UserInfo, publicFlag) {

    const groupKey = uuid4();
    const ext = file.name.split('.').pop();
    const date = new Date();
    const dateKey = date.toISOString().substring(0, 10);
    let filename = `${dateKey}/${groupKey}/${uuid4()}.${ext}`;

    if (!publicFlag) {
        const stored = await Storage.vault.put(filename, file, {
            contentType: file.type,
        });

        return {key: stored.key, groupKey, dateKey};
    } else {
        filename = UserInfo.id + "/" + filename;
        const stored = await Storage.put(filename, file, {
            contentType: file.type,
        });

        console.log("stored === " + JSON.stringify(stored));

        return {key: stored.key, groupKey, dateKey}
    }
}

export async function s3UploadAvatar(file, UserInfo) {

    const ext = file.name.split('.').pop();
    let filename = `avatar/${uuid4()}.${ext}`;

    filename = UserInfo.id + "/" + filename;
    const stored = await Storage.put(filename, file, {
        contentType: file.type,
    });

    console.log("stored === " + JSON.stringify(stored));

    return {key: stored.key}

}

export async function s3UploadUserSubscriptionProductImage(file, UserInfo) {

    const ext = file.name.split('.').pop();
    let filename = `userSubscriptionProducts/${uuid4()}.${ext}`;

    filename = UserInfo.id + "/" + filename;
    const stored = await Storage.put(filename, file, {
        contentType: file.type,
    });

    console.log("stored === " + JSON.stringify(stored));

    return {key: stored.key}

}

export async function s3Delete(key, publicFlag) {

    let response;

    if (!publicFlag) {
        response = await Storage.vault.remove(key);
    } else {
        response = await Storage.remove(key);
    }

    return response
}

export async function getImageUrl(image, groupId) {
    let url = "";
    if (image && groupId === "PRIVATE") {
        url = await Storage.vault.get(image);
    } else if (image && groupId === "PUBLIC") {
        url = await Storage.get(image);
    }
    console.log("url === " + url);
    console.log("short url === " + url.split("?")[0])
    return url;
}

export function loadProfile(userInfo) {
    return API.get("ixie", "profile/" + userInfo.id);
}
