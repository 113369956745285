import "./ChangeEmail.css";

import React, {useState} from "react";

import {Auth} from "aws-amplify";
import {
    FormGroup,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Button
} from "@mui/material";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";
import {useNavigate} from "react-router-dom";

export default function ChangeEmail() {
    const navigate = useNavigate();
    const [codeSent, setCodeSent] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        email: "",
    });
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    const {userInfo} = useAppContext();

    function validateEmailForm() {
        return fields.email.length > 0;
    }

    function validateConfirmForm() {
        return fields.code.length > 0;
    }

    async function handleUpdateClick(event) {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {email: fields.email});
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event) {
        event.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);

            navigate("/profile");
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    }

    function renderUpdateForm() {
        return (
            <form onSubmit={handleUpdateClick}>
                <FormGroup>
                    <p>Current email address: {userInfo.attributes.email}</p>
                    <p>
                        After pressing "Update Email", you will receive a confirmation code
                        to the new email address and be prompted to enter it on the next
                        screen.
                    </p>
                    <TextField
                        id="email"
                        name="Email"
                        label="Email"
                        type="email"
                        value={fields.email}
                        placeholder={userInfo.attributes.email}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <Button
                    block
                    type="submit"
                    size="lg"
                    isLoading={isSendingCode}
                    disabled={!validateEmailForm()}
                >
                    Update Email
                </Button>
            </form>
        );
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmClick}>
                <FormGroup>
                    <TextField
                        id="code"
                        name="Confirmation Code"
                        label="Confirmation Code"
                        type="tel"
                        value={fields.code}
                        onChange={handleFieldChange}
                    />
                    <FormHelperText>
                        Please check your email ({fields.email}) for the confirmation code.
                    </FormHelperText>
                </FormGroup>
                <Button
                    block
                    type="submit"
                    size="lg"
                    isLoading={isConfirming}
                    disabled={!validateConfirmForm()}
                >
                    Confirm
                </Button>
            </form>
        );
    }

    return (
        <div className="ChangeEmail">
            {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
        </div>
    );
}
