import "./Profile.css";

import {API, Storage} from "aws-amplify";
import {BsGearWide, BsPencilSquare} from "react-icons/bs";
import {
    Container,
    Stack,
    Grid,
    FormGroup,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Button
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";

import {Link} from "react-router-dom";
import NotFound from "./NotFound";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";

export default function Profile() {
    const [profileId, setProfileId] = useState(null);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(useAppContext().userInfo);
    const [profile, setProfile] = useState({});
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isFollowed, setIsFollowed] = useState(false);
    const [subscriptionsAvailable, setSubscriptionsAvailable] = useState(false);
    const [subscribed, setSubscribed] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();
    const profileCriteria = location.state;

    const {isAuthenticated} = useAppContext();

    const {username} = useParams();

    useEffect(() => {
        async function onLoad() {
            try {
                const profile = await loadProfile();
                if (profile === null) {
                    setProfile(null);
                } else {
                    const availableSubscriptions = await loadAvailableSubscriptions(
                        profile.userId
                    );

                    if (
                        Array.isArray(availableSubscriptions) &&
                        availableSubscriptions.length > 0
                    ) {
                        setSubscriptionsAvailable(true);
                        const userSubscriptions = await loadSubscriptions(profile.userId);
                        userSubscriptions.forEach((sub) => {
                            if (sub.status === "active") {
                                setSubscribed(true);
                            }
                        });
                    }

                    if (profile.Avatar) {
                        profile.smallSq = await getImageUrl(
                            profile.Avatar.smallSq,
                            "PUBLIC"
                        );
                    }

                    setProfile(profile);

                    try {
                        const posts = await loadPosts(
                            profile.userId,
                            profileCriteria ? profileCriteria.feed : null
                        );
                        //get post images
                        let postPromises = posts.map(async (post) => {
                            if (post.groupId === "PUBLIC") {
                                //post.microSq = await getImageUrl(post.Image.microSq, post.groupId);
                                post.smallSq = await getImageUrl(
                                    post.Image.smallSq,
                                    post.groupId
                                );
                                //post.mediumSq = await getImageUrl(post.Image.mediumSq, post.groupId);
                            } else {
                                post.smallSq = await getSubscriptionImageUrl(
                                    post.postId,
                                    "smallSq"
                                );
                            }
                        });
                        await Promise.all(postPromises);
                        setPosts(posts);
                    } catch (e) {
                        onError(e);
                    }

                    try {
                        await updateFollowInfo(profile.userId);
                    } catch (e) {
                        onError(e);
                    }
                }
            } catch (e) {
                if (e.message === "Item not found.") {
                    setProfile(null);
                } else {
                    //onError(e);
                }
            }

            setIsLoading(false);
        }

        onLoad();
    }, []);

    function loadPosts(userId, feed) {
        return API.get("ixie", "userPosts/" + userId + "/" + feed);
    }

    async function loadProfile() {
        let profileId;
        if (profileCriteria && profileCriteria.userId) {
            profileId = profileCriteria.userId;
        } else if (username) {
            let lowerUsername = username.toLowerCase();
            let profile = await API.get("ixie", "profile/find/" + lowerUsername);
            if (profile) {
                profileId = profile.userId;
                setProfileId(profileId);
                return profile;
            }
            return null;
        } else {
            profileId = userInfo.id;
        }
        setProfileId(profileId);
        return await API.get("ixie", "profile/" + profileId);
    }

    async function loadAvailableSubscriptions(userId) {
        return API.get("ixie", "userProducts/subscription/" + userId);
    }

    async function loadSubscriptions(id) {
        return API.get("ixie", "user/subscriptions/" + id);
    }

    async function getImageUrl(image, groupId) {
        if (image && groupId === "PRIVATE") {
            return await Storage.vault.get(image);
        } else if (image && groupId === "PUBLIC") {
            return await Storage.get(image);
        }
    }

    async function getSubscriptionImageUrl(postId, size) {
        return API.get("ixie", "subscription/getimageurl/" + postId + "/" + size);
    }

    function getRows(items) {
        let rows = [];

        while (items.length > 0) {
            let item1 = items.shift();
            let item2 = items.shift();
            let item3 = items.shift();
            let row = (
                <div className="row" key={"row" + rows.length + 1}>
                    <div className="col">{item1 ? item1 : ""}</div>
                    <div className="col">{item2 ? item2 : ""}</div>
                    <div className="col">{item3 ? item3 : ""}</div>
                </div>
            );
            rows.push(row);
        }

        return rows;
    }

    async function handleFollowButton(event) {
        event.preventDefault();
        await follow(profileId);
        await updateFollowInfo(profileId);
    }

    async function handleSubscribeButton(event) {
        event.preventDefault();
        navigate(
            "/" +
            (profile.username ? profile.username : profile.userId) +
            "/subscribe",
            {
                state: {
                    userId: profile.userId,
                },
            });
    }

    async function updateFollowInfo(userId) {
        let followers = await loadFollowers(userId);
        let following = await loadFollowing(userId);
        setFollowers(followers);
        setFollowing(following);
        if (isAuthenticated) {
            setIsFollowing(
                followers.find((follower) => follower.followerUserId === userInfo.id)
                    ? true
                    : false
            );
            setIsFollowed(
                following.find((follower) => follower.userId === userInfo.id)
                    ? true
                    : false
            );
        }
    }

    async function loadFollowers(userId) {
        return API.get("ixie", "followers/" + userId);
    }

    async function loadFollowing(userId) {
        return API.get("ixie", "following/" + userId);
    }

    async function follow(userId) {
        return API.post("ixie", "follow/" + userId);
    }

    function gotoProfile(event, feed = "public") {
        event.preventDefault();
        let addToPath = "";
        if (feed && feed !== "" && feed !== "public") {
            addToPath = "/" + feed;
        }
        navigate(
            "/" +
            (profile.username ? profile.username : profile.userId) +
            addToPath,
            {
                state: {
                    userId: profile.userId,
                    feed
                }
            });
    }

    function renderPostsList(posts) {
        let items = [];

        for (let i = 0; i < posts.length; i++) {
            let post = posts[i];
            let item = (
                <Link key={post.postId} to={`/posts/${post.postId}`}>
                    <img src={post.smallSq} className="img-fluid"/>
                </Link>
            );

            items.push(item);
        }

        let rows = getRows(items);

        return (
            <div className="container">
                {rows.map((row) => {
                    return row;
                })}
            </div>
        );
    }

    function renderPosts() {
        return (
            <div className="posts">
                <Grid>
                    <Button variant="link" onClick={gotoProfile}>
                        <span>Public</span>
                    </Button>
                    {(profile.userId === userInfo.id ||
                        (subscriptionsAvailable && subscribed)) && (
                        <Button
                            variant="link"
                            onClick={(e) => gotoProfile(e, "subscriber")}
                        >
                            <span>Subscriber Feed</span>
                        </Button>
                    )}
                </Grid>
                <Stack>
                    {!isLoading && renderPostsList(posts)}
                </Stack>
            </div>
        );
    }

    function renderProfile() {
        return (
            <Container>
                <Stack>
                    <Grid>
                        <span>{profile.username}</span>
                        {isAuthenticated && userInfo.id === profileId && (
                            <Link to="/profile/username">
                                <Button variant="link">
                                    <BsPencilSquare/>
                                </Button>
                            </Link>
                        )}

                        {isAuthenticated && userInfo.id === profileId && (
                            <Link to="/settings">
                                <Button variant="link">
                                    <BsGearWide/>
                                </Button>
                            </Link>
                        )}
                    </Grid>
                    {isAuthenticated && userInfo.id === profileId && (
                        <Grid>
                            <span>{userInfo.attributes.email}</span>
                            <Link to="/profile/email">
                                <Button variant="link">
                                    <BsPencilSquare/>
                                </Button>
                            </Link>
                        </Grid>
                    )}
                    {isAuthenticated && userInfo.id === profileId && (
                        <Grid>
                            <span>Change Password</span>
                            <Link to="/profile/password">
                                <Button variant="link">
                                    <BsPencilSquare/>
                                </Button>
                            </Link>
                        </Grid>
                    )}
                    <Grid>
                        {!isLoading && profile.smallSq && (
                            <img src={profile.smallSq} className="img-fluid"/>
                        )}
                        <Stack>
                            <Grid>
                                <>
                                    Posts
                                    <br/>
                                    {!isLoading && posts.length}
                                </>
                                <>
                                    Followers
                                    <br/>
                                    {!isLoading && followers.length}
                                </>
                                <>
                                    Following
                                    <br/>
                                    {!isLoading && following.length}
                                </>
                            </Grid>
                            <p>{!isLoading && profile.profile}</p>
                        </Stack>
                    </Grid>
                    {isAuthenticated && userInfo.id === profileId && (

                        <Link to="/profile/edit">
                            <Button block size="lg">
                                Edit Profile
                            </Button>
                        </Link>

                    )}
                    {isAuthenticated && userInfo.id !== profileId && (
                        <Grid>
                            <Button block size="lg" onClick={handleFollowButton}>
                                {isFollowing && <>Unfollow</>}
                                {!isFollowing && !isFollowed && <>Follow</>}
                                {!isFollowing && isFollowed && <>Follow Back</>}
                            </Button>
                            {subscriptionsAvailable && (
                                <Button block size="lg" onClick={handleSubscribeButton}>
                                    {subscribed && <>Change Subscription</>}
                                    {!subscribed && <>Subscribe</>}
                                </Button>
                            )}
                        </Grid>
                    )}
                </Stack>
            </Container>
        );
    }

    return (
        <>
            {profile !== null ? (
                <div className="Profile">
                    {renderProfile()}
                    {renderPosts()}
                </div>
            ) : (
                <NotFound/>
            )}
        </>
    );
}
