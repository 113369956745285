import "./ResetPassword.css";

import React, {useState} from "react";

import {Auth} from "aws-amplify";
import {BsCheck} from "react-icons/bs";
import {
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";
import {Link} from "react-router-dom";
import {onError} from "../libs/errorLib";
import {useFormFields} from "../libs/hooksLib";

export default function ResetPassword() {
    const [fields, handleFieldChange] = useFormFields({
        codeInput: "",
        emailInput: "",
        passwordInput: "",
        confirmPasswordInput: "",
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateCodeForm() {
        return fields.emailInput.length > 0;
    }

    function validateResetForm() {
        return (
            fields.codeInput.length > 0 &&
            fields.passwordInput.length > 0 &&
            fields.passwordInput === fields.confirmPasswordInput
        );
    }

    async function handleSendCodeClick(event) {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.emailInput);
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event) {
        event.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(
                fields.emailInput,
                fields.codeInput,
                fields.passwordInput
            );
            setConfirmed(true);
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    }

    function renderRequestCodeForm() {
        return (
            <form onSubmit={handleSendCodeClick}>
                <FormGroup>
                    <TextField
                        id="emailInput"
                        name="Email"
                        label="Email"
                        type="email"
                        value={fields.emailInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <Button
                    block
                    type="submit"
                    size="lg"
                    isLoading={isSendingCode}
                    disabled={!validateCodeForm()}
                >
                    Send Confirmation
                </Button>
            </form>
        );
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmClick}>
                <FormGroup>
                    <TextField
                        id="codeInput"
                        name="Confirmation Code"
                        label="Confirmation Code"
                        type="tel"
                        value={fields.codeInput}
                        onChange={handleFieldChange}
                    />
                    <FormHelperText>
                        Please check your email ({fields.emailInput}) for the confirmation code.
                    </FormHelperText>
                </FormGroup>
                <hr/>
                <FormGroup>
                    <TextField
                        id="passwordInput"
                        name="New Password"
                        label="New Password"
                        type="password"
                        value={fields.passwordInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        id="confirmPasswordInput"
                        name="Confirm Password"
                        label="Confirm Password"
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <Button
                    block
                    type="submit"
                    size="lg"
                    isLoading={isConfirming}
                    disabled={!validateResetForm()}
                >
                    Confirm
                </Button>
            </form>
        );
    }

    function renderSuccessMessage() {
        return (
            <div className="success">
                <BsCheck size={17}/>
                <p>Your password has been reset.</p>
                <p>
                    <Link to="/login">
                        <span>Click here to login with your new credentials.</span>
                    </Link>
                </p>
            </div>
        );
    }

    return (
        <div className="ResetPassword">
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}
        </div>
    );
}
