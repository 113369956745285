import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewPost from "./containers/NewPost";
import Posts from "./containers/Posts";
import Profile from "./containers/Profile";
import ProfileEdit from "./containers/ProfileEdit";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";
import ChangeUsername from "./containers/ChangeUsername";
import Tip from "./containers/Tip";
import NotFound from "./containers/NotFound";
import Conversations from "./containers/Conversations";
import Conversation from "./containers/Conversation";
import Settings from "./containers/Settings";
import SubscriptionProductsEdit from "./containers/SubscriptionProductsEdit";
import SubscriptionProductsList from "./containers/SubscriptionProductsList";
import Subscribe from "./containers/Subscribe";
import {useAppContext} from "./libs/contextLib";

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<Home/>}/>
                <Route path="tags/:tag" element={<Home/>}/>

                <Route path="feed" element={<AuthenticatedRoute redirectTo="/login"><Home/></AuthenticatedRoute>}/>
                <Route path="subscriptions"
                       element={<AuthenticatedRoute redirectTo="/login"><Home/></AuthenticatedRoute>}/>

                <Route path="login">
                    <Route index element={<UnauthenticatedRoute><Login/></UnauthenticatedRoute>}/>
                    <Route path="reset" element={<UnauthenticatedRoute><ResetPassword/></UnauthenticatedRoute>}/>
                </Route>

                <Route path="signup" element={<UnauthenticatedRoute><Signup/></UnauthenticatedRoute>}/>

                <Route path="posts">
                    <Route index element={<Home/>}/>
                    <Route path="new"
                           element={<AuthenticatedRoute redirectTo="/login"><NewPost/></AuthenticatedRoute>}/>
                    <Route path=":id" element={<Posts/>}/>
                </Route>

                <Route path="subscriptionproducts/:userId"
                       element={<AuthenticatedRoute
                           redirectTo="/login"><SubscriptionProductsList/></AuthenticatedRoute>}/>

                <Route path="profile">
                    <Route index element={<AuthenticatedRoute redirectTo="/login"><Profile/></AuthenticatedRoute>}/>
                    <Route path="edit"
                           element={<AuthenticatedRoute redirectTo="/login"><ProfileEdit/></AuthenticatedRoute>}/>
                    <Route path="username"
                           element={<AuthenticatedRoute redirectTo="/login"><ChangeUsername/></AuthenticatedRoute>}/>
                    <Route path="email"
                           element={<AuthenticatedRoute redirectTo="/login"><ChangeEmail/></AuthenticatedRoute>}/>
                    <Route path="password"
                           element={<AuthenticatedRoute redirectTo="/login"><ChangePassword/></AuthenticatedRoute>}/>
                    <Route path=":username" element={<Profile/>}/>
                </Route>

                <Route path="settings">
                    <Route index element={<AuthenticatedRoute redirectTo="/login"><Settings/></AuthenticatedRoute>}/>
                    <Route path="subscriptionproduct/edit/:id" element={<AuthenticatedRoute
                        redirectTo="/login"><SubscriptionProductsEdit/></AuthenticatedRoute>}/>
                    <Route path="subscriptionproducts" element={<AuthenticatedRoute
                        redirectTo="/login"><SubscriptionProductsList/></AuthenticatedRoute>}/>
                </Route>

                <Route path="messages">
                    <Route index
                           element={<AuthenticatedRoute redirectTo="/login"><Conversations/></AuthenticatedRoute>}/>
                    <Route path=":username"
                           element={<AuthenticatedRoute redirectTo="/login"><Conversation/></AuthenticatedRoute>}/>
                </Route>

                <Route path="tip" element={<AuthenticatedRoute redirectTo="/login"><Tip/></AuthenticatedRoute>}/>

                <Route path=":username">
                    <Route index element={<Profile/>}/>

                    <Route path="subscribe">
                        <Route index element={<AuthenticatedRoute
                            redirectTo="/login"><SubscriptionProductsList/></AuthenticatedRoute>}/>
                        <Route path=":tier"
                               element={<AuthenticatedRoute redirectTo="/login"><Subscribe/></AuthenticatedRoute>}/>
                    </Route>
                    <Route path=":feed"
                           element={<AuthenticatedRoute redirectTo="/login"><Profile/></AuthenticatedRoute>}/>
                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Route>

        </Routes>
    );
}

function AuthenticatedRoute({children, redirectTo}) {
    const {pathname, search} = useLocation();
    const {isAuthenticated} = useAppContext();
    return isAuthenticated ? children : <Navigate to={redirectTo + `?redirect=${pathname}${search}`}/>;
}

function UnauthenticatedRoute({children}) {
    const redirect = querystring("redirect");
    const {isAuthenticated} = useAppContext();
    // console.log("isAuthenticated === " + isAuthenticated);
    return !isAuthenticated ? children : <Navigate to={redirect === "" || redirect === null ? "/" : redirect}/>;
}

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
