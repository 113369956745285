export const WelcomeMessage = () => (
  <div>
    <p>Welcome to ixie</p>
    <span>
      This site/app is still in the very early stages of development, but we
      hope things will be progressing rapidly. The site will have a public side,
      free to browse content and posting, and a pay/subscription side where the
      content creators can setup their own subscription plans and sell content.
      ...But we're not there yet. Right now you can signup, login and upload and
      browse content .. and please do! The features are limited, but we do need
      more data in the system for testing. Hashtags *kinda* work in the post
      text, so give them a try.
    </span>
    <br />
    <br />
    <span>
      If you start to get frustrated with a the site, please just take a break
      and look at the site again in a few days.. hopefully we'll have fixed
      whatever was causing you the problem
    </span>
    <br />
    <br />
    <span>
      Last note, all the content in the development site will eventually be
      deleted. We might do that several times in fact... it is development.
    </span>
    <br />
    <br />
  </div>
);
export default WelcomeMessage;
