// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import {API, Storage} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {
    Link,
    useLocation,
    useParams
} from "react-router-dom";
import {Stack} from "@mui/material";
import Loading from "../components/Loading";
import WelcomeMessage from "../components/WelcomeMessage";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";

import {
    ImageList,
    ImageListItem
} from "@mui/material";

export default function Home() {
    const {tag} = useParams();
    const [posts, setPosts] = useState([]);
    const {isAuthenticated} = useAppContext();
    const [hasSubscriptions, setHasSubscriptions] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [heading, setHeading] = useState("");

    const location = useLocation();

    useEffect(() => {
        async function onLoad() {
            try {
                if (isAuthenticated) {
                    let subscriptions = await loadSubscriptions();
                    subscriptions = subscriptions.filter(
                        (sub) => sub.status === "active"
                    );
                    if (subscriptions.length > 0) {
                        setHasSubscriptions(true);
                    }
                }

                const posts = await loadPosts();
                //get post images
                let postPromises = posts.map(async (post) => {
                    if (post.groupId === "PUBLIC") {
                        //post.microSq = await getImageUrl(post.Image.microSq, post.groupId);
                        post.smallSq = await getImageUrl(post.Image.smallSq.key, post.groupId);
                        post.medium = await getImageUrl(post.Image.medium.key, post.groupId);
                    } else {
                        post.smallSq = await getSubscriptionImageUrl(
                            post.postId,
                            "smallSq"
                        );
                        post.medium = await getSubscriptionImageUrl(
                            post.postId,
                            "medium"
                        );
                    }
                });
                await Promise.all(postPromises);
                setPosts(posts);
            } catch (e) {
                onError(e);
            } finally {
                setIsLoading(false);
            }
        }

        onLoad();
    }, [tag, location]);

    async function loadPosts() {
        if (isAuthenticated && location.pathname === "/feed") {
            setHeading("My Feed");
            return API.get("ixie", "feed");
        } else if (hasSubscriptions && location.pathname === "/subscriptions") {
            setHeading("Subscriptions");
            return API.get("ixie", "subscriptions");
        } else {
            if (tag) {
                setHeading("Explore - " + tag);
                return API.get("ixie", "public/" + tag);
            } else {
                setHeading("Explore");
                return API.get("ixie", "public");
            }
        }
    }

    async function loadSubscriptions() {
        return API.get("ixie", "user/subscriptions");
    }

    async function getImageUrl(image, groupId) {
        if (image && groupId === "PRIVATE") {
            return await Storage.vault.get(image);
        } else if (image && groupId === "PUBLIC") {
            return await Storage.get(image);
        }
    }

    async function getSubscriptionImageUrl(postId, size) {
        return API.get("ixie", "subscription/getimageurl/" + postId + "/" + size);
    }

    return (
        <div className="Home">
            {!isAuthenticated && <WelcomeMessage/>}
            {isLoading ? (
                <Loading align="center"/>
            ) : (
                <Stack>
                    <h1>{heading}</h1>
                    <ImageList
                        variant="quilted"
                        cols={4}
                    >
                        {posts.map((post, i) => (
                            <Link key={post.postId} to={"/posts/" + post.postId}>
                                <ImageListItem cols={post.cols || 1} rows={post.rows || 1}>
                                    <img
                                        src={post.smallSq}
                                        alt={post.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            </Link>

                        ))}
                    </ImageList>
                </Stack>
            )}
        </div>
    );
}
