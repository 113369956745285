import "./Conversations.css";

import React, {useEffect, useState} from "react";
import {getProfile, getUserAndConversations} from "../libs/graphqlLib";
import {useNavigate, useParams} from "react-router-dom";

import {Link} from "react-router-dom";
import {
    Stack
} from "@mui/material";
import Search from "../components/Search";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";
import {useLazyQuery} from "@apollo/client";

export default function Conversations() {
    const [isLoading, setIsLoading] = useState(true);
    const [conversations, setConversations] = useState([]);

    const {userInfo} = useAppContext();

    const [getConversations, {loading, error, data}] = useLazyQuery(
        getUserAndConversations,
        {
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => parseConversations(data),
        }
    );

    const navigate = useNavigate();

    useEffect(() => {
        async function onLoad() {
            try {
                getConversations({variables: {userId: userInfo.id}});
            } catch (e) {
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, []);

    function parseConversations(conversationData) {
        setConversations(conversationData.getProfile.conversations.items);
    }

    function openConversation(conversationId, e) {
        e.preventDefault();
        navigate(
            "/messages/" + conversationId,
            {
                state: {
                    conversationId: conversationId,
                },
            });
    }

    function renderConversations() {
        return (
            <Stack>
                <Search/>
                {Array.isArray(conversations) && conversations.length > 0 ? (
                    conversations.map((conversation) => (
                        <div
                            key={conversation.conversation.conversationId}
                            onClick={(e) =>
                                openConversation(conversation.conversation.conversationId, e)
                            }
                        >
                            {conversation.conversation.conversationId}
                        </div>
                    ))
                ) : (
                    <div>
                        No messages. Time to say hi to a friend :)
                    </div>
                )}
            </Stack>
        );
    }

    return renderConversations();
}
