const config = {
    STRIPE_KEY: "pk_test_lM5vL5N5mjItvURTaOQbDKc8",
    MAX_ATTACHMENT_SIZE: 50000000,
    s3: {
        REGION: "us-east-1",
        BUCKET: "ixie.dev.content",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://api.dev.ixie.works/",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_WFiRIFQ21",
        APP_CLIENT_ID: "54ogdng9krhbfi0lts0mhtijp3",
        IDENTITY_POOL_ID: "us-east-1:cf10ea6f-9915-4406-950e-2da883bd9f8a",
    },
    appsync: {
        REGION: "us-east-1",
        URL: "https://wfi2kqtaavbzrbdwul7rr6ne4a.appsync-api.us-east-1.amazonaws.com/graphql",
        AUTHTYPE: "AWS_IAM",
        APIKEY: "rnq5wmtx2fctpauk6cwg4azsqe"
    }
};

export default config;
