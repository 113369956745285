import "./SubscriptionProductsEdit.css";

import { API, Storage } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";

import Button from "../components/Button";
import config from "../config";
import { onError } from "../libs/errorLib";
import { s3UploadAvatar } from "../libs/awsLib";
import { useAppContext } from "../libs/contextLib";
import { useNavigate } from "react-router-dom";

export default function SubscriptionProductsEdit() {
  // const file = useRef(null);
  // const navigate = useNavigate();
  // const [subscriptionProduct, setSubscriptionProduct] = useState({});
  // const [description, setDescription] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  //
  // const {userInfo} = useAppContext();
  //
  // useEffect(() => {
  //     async function onLoad() {
  //
  //         try {
  //             const profile = await loadProfile();
  //             profile.smallSq = await getImageUrl(profile.Avatar.smallSq, "PUBLIC");
  //             setProfile(profile);
  //             setContent(profile.profile);
  //         } catch (e) {
  //             if (e.message === "Item not found.") {
  //                 setProfile({});
  //             } else {
  //                 //onError(e);
  //             }
  //         }
  //
  //         setIsLoading(false);
  //     }
  //
  //     onLoad();
  // }, []);
  //
  // function validateForm() {
  //     return content.length > 0;
  // }
  //
  // function handleFileChange(event) {
  //     file.current = event.target.files[0];
  // }
  //
  // async function handleSubmit(event) {
  //     event.preventDefault();
  //
  //     if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
  //         alert(
  //             `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
  //             1000000} MB.`
  //         );
  //         return;
  //     }
  //
  //     setIsLoading(true);
  //
  //     try {
  //         let profile = {};
  //         if (file.current) {
  //             const {key} = file.current ? await s3UploadAvatar(file.current, userInfo) : null;
  //
  //             const Image = {
  //                 original: key,
  //                 filename: file.current.name,
  //                 bucket: config.s3.BUCKET
  //             }
  //             profile.Image = Image;
  //         }
  //
  //         profile.profile = content;
  //
  //         await updateProfile(profile);
  //         navigate("/profile");
  //     } catch (e) {
  //         onError(e);
  //         setIsLoading(false);
  //     }
  // }
  //
  // function loadProfile() {
  //     return API.get("ixie", "profile/" + userInfo.id);
  // }
  //
  // function updateProfile(content) {
  //     return API.post("ixie", "profile", {
  //         body: content
  //     });
  // }
  //
  // async function getImageUrl(image, groupId) {
  //     if (image && groupId === "PRIVATE") {
  //         return await Storage.vault.get(image);
  //     } else if (image && groupId === "PUBLIC") {
  //         return await Storage.get(image);
  //     }
  // }
  //
  // return (
  //     <div className="ProfileEdit">
  //         <Form onSubmit={handleSubmit}>
  //             <Container>
  //                 <Row>
  //                     <Col>
  //                         <span>{userInfo.attributes.preferred_username}</span>
  //                         {profile && profile.smallSq && (<img src={profile.smallSq} className="img-fluid"/>)}
  //                         <Form.Group controlId="file">
  //                             <Form.Label>Attachment</Form.Label>
  //                             <Form.Control
  //                                 onChange={handleFileChange}
  //                                 type="file"
  //                                 accept="image/png,image/jpeg"
  //                             />
  //                         </Form.Group>
  //                     </Col>
  //                     <Col>
  //                         <Form.Group controlId="content">
  //                             <Form.Control
  //                                 value={content}
  //                                 as="textarea"
  //                                 onChange={(e) => setContent(e.target.value)}
  //                             />
  //                         </Form.Group>
  //                     </Col>
  //                 </Row>
  //                 <Row>
  //                     <Col>
  //                         <Button
  //                             block
  //                             type="submit"
  //                             size="lg"
  //                             variant="primary"
  //                             isLoading={isLoading}
  //                             disabled={!validateForm()}
  //                         >
  //                             Update
  //                         </Button>
  //                     </Col>
  //                 </Row>
  //             </Container>
  //         </Form>
  //     </div>
  // );
}
