import {API} from "aws-amplify";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";
import {BsSearch} from "react-icons/bs";

import "./Search.css";
import {useAppContext} from "../libs/contextLib";

export default function Search({
                                   isLoading,
                                   className = "",
                                   disabled = false,
                                   ...props
                               }) {

    const [query, setQuery] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const {userInfo} = useAppContext();

    const navigate = useNavigate();

    function handleOnInputChange(event) {
        const query = event.target.value;
        if (!query) {
            setSearchResults([]);
            setQuery(query);
            setMessage("");
            setLoading(false);
        } else {
            setQuery(query);
            setLoading(true);
            setMessage("");
            searchProfiles(query);
        }
    }

    function startConversation(profile, e) {
        e.preventDefault();
        navigate(
            "/messages/" + profile.username,
            {
                state: {
                    userId: profile.userId
                }
            });
    }

    function searchProfiles(searchTerm) {
        API.get("ixie", "profile/search/" + searchTerm).then(results => {
            //console.log(results);
            setSearchResults(results.filter(result => result.userId !== userInfo.id));
            setLoading(false);
        });
    }

    function renderSearchResults() {
        if (searchResults.length > 0) {
            return (<div>
                {searchResults.map(result => {
                    return (
                        <a key={result.userId} onClick={(e) => startConversation(result, e)}>{result.username}</a>
                    );
                })}
            </div>);
        }
    }

    return (
        <div className={`Search ${className}`}>
            <FormGroup>
                <TextField
                    type="text"
                    label="Search"
                    id="search"
                    name="Search"
                    onChange={handleOnInputChange}
                    autoComplete="off"
                />
                <BsSearch/>
            </FormGroup>
            {renderSearchResults()}
        </div>

    );
}
