import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    ButtonGroup
} from "@mui/material";
import {
    Menu as MenuIcon,
    Chat as ChatIcon,
    PostAdd as PostAddIcon
} from "@mui/icons-material";
import {Link} from "react-router-dom";

import {useAppContext} from "../libs/contextLib";
import {getImageUrl} from "../libs/awsLib";

function Navbar() {


    const {profile, userInfo, isAuthenticated, handleLogout} = useAppContext();

    const guestPages = [
        {name: "Explore", to: "/"}
    ];
    const userPages = [
        {name: "Explore", to: "/"},
        {name: "Feed", to: "/feed"},
        {name: "Subscriptions", to: "/subscriptions"}
    ];
    const settings = [
        {name: "Profile", to: "/profile"},
        {name: "Settings", to: "/settings"},
        {
            name: "Logout",
            onClick: handleLogout
        }
    ];

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    useEffect(() => {
        async function onLoad() {
            try {
                if (profile && profile.Avatar && profile.Avatar.smallSq) {
                    profile.smallSq = await getImageUrl(profile.Avatar.smallSq, "PUBLIC");
                }
                console.log("profile === " + JSON.stringify(profile));
            } catch (e) {
                console.log(e.message);
            }
        }

        onLoad();
    }, []);

    function handleOpenNavMenu(event) {
        setAnchorElNav(event.currentTarget);
    }
    function handleOpenUserMenu(event) {
        setAnchorElUser(event.currentTarget);
    }

    function handleCloseNavMenu() {
        setAnchorElNav(null);
    }
    function handleCloseUserMenu() {
        setAnchorElUser(null);
    }

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/*<IxieIcon sx={{ display: {xs: 'none', md: 'flex'}, mr: 1 }} />*/}
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: {xs: "none", md: "flex"},
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none"
                        }}
                    >
                        ixie
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {!isAuthenticated ? (
                                guestPages.map((page) => (
                                    <MenuItem
                                        key={page.name}
                                        component={page.to ? Link : "a"}
                                        href={page.href}
                                        to={page.to}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))
                            ) : (
                                userPages.map((page) => (
                                    <MenuItem
                                        key={page.name}
                                        component={page.to ? Link : "a"}
                                        href={page.href}
                                        to={page.to}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                ))
                            )}
                        </Menu>
                    </Box>

                    {/*<IxieIcon sx={{ display: {xs: 'none', md: 'flex'}, mr: 1 }} />*/}
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: {xs: "flex", md: "none"},
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none"
                        }}
                    >
                        ixie
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {!isAuthenticated ? (
                            guestPages.map((page) => (
                                <Button
                                    key={page.name}
                                    component={page.to ? Link : "a"}
                                    href={page.href}
                                    to={page.to}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block"}}
                                >
                                    {page.name}
                                </Button>
                            ))) : (
                            userPages.map((page) => (
                                <Button
                                    key={page.name}
                                    component={page.to ? Link : "a"}
                                    href={page.href}
                                    to={page.to}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block"}}
                                >
                                    {page.name}
                                </Button>
                            ))
                        )}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        {isAuthenticated && userInfo ? (
                            <>
                                <Tooltip title="Chat">
                                    <IconButton
                                        component={Link}
                                        to="messages"
                                        sx={{p: 0}}
                                    >
                                        <ChatIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Post">
                                    <IconButton
                                        component={Link}
                                        to="posts/new"
                                        sx={{p: 0}}
                                    >
                                        <PostAddIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt={userInfo.attributes.preferred_username} src={profile && profile.smallSq}/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem
                                            key={setting.name}
                                            component={setting.to ? Link : "a"}
                                            href={setting.href}
                                            to={setting.to}
                                            onClick={setting.onClick ? setting.onClick : handleCloseUserMenu}
                                        >
                                            <Typography textAlign="center">{setting.name}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        ) : (
                            <ButtonGroup>
                                <Button
                                    key="Signup"
                                    component={Link}
                                    to="/signup"
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    Signup
                                </Button>
                                <Button
                                    key="Login"
                                    component={Link}
                                    to="/login"
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    Login
                                </Button>
                            </ButtonGroup>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navbar;
