import "./Login.css";

import {API, Auth} from "aws-amplify";
import React, {useState} from "react";

import {
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";
import {Link} from "react-router-dom";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";

export default function Login() {
    const [isLoading, setIsLoading] = useState(false);

    const {userHasAuthenticated, setUserInfo} = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        emailInput: "",
        passwordInput: "",
    });

    function validateForm() {
        return fields.emailInput.length > 0 && fields.passwordInput.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.signIn(fields.emailInput, fields.passwordInput);
            let UserInfo = await Auth.currentUserInfo();
            console.log({userInfo: UserInfo});
            if (
                !UserInfo.attributes["custom:has_profile"] ||
                UserInfo.attributes["custom:has_profile"] != 1
            ) {
                console.log("making profile");
                let result = await API.get("ixie", "profile/create");
                if (result) {
                    UserInfo.attributes["custom:has_profile"] = "1";
                }
            }
            setUserInfo(UserInfo);
            userHasAuthenticated(true);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="Login">
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <TextField
                        id="emailInput"
                        name="Email"
                        label="Email"
                        type="email"
                        value={fields.emailInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        id="passwordInput"
                        name="Password"
                        label="password"
                        type="password"
                        value={fields.passwordInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <Link to="/login/reset">
                    <span className="ForgotPassword">Forgot password?</span>
                </Link>
                <Button
                    block
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Login
                </Button>
            </form>
        </div>
    );
}
