import React, {useState, useEffect} from "react";
import config from "../config";
import CheckoutForm from "../components/CheckoutForm";
import "./Tip.css";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {Container} from "@mui/material";

export default function Tip() {

    const stripePromise = loadStripe(config.STRIPE_KEY);

    return (
        <Container>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </Container>
    );

}
