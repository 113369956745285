import {gql} from '@apollo/client';

// mutations
const createMessage = gql`mutation CreateMessage(
    $createdAt: Long, $messageId: ID, $userId: String, $content: String!, $conversationId: ID!
) {
    createMessage(input: {
        createdAt: $createdAt, messageId: $messageId, content: $content, conversationId: $conversationId, userId: $userId
    }) {
        messageId
        content
        userId
        conversationId
        createdAt
    }
}
`;


const createConversation = `mutation CreateConversation($name: String!, $members: [String!]!) {
  createConversation(input: {
    name: $name, members: $members
  }) {
    conversationId
    name
    members
  }
}
`;

const createParticipant = `mutation CreateParticipant(
    $conversationId: ID!, $userId: ID
  ) {
  createParticipant(input: {
    conversationId: $conversationId, userId: $userId
  }) {
    participantId
    userId
    conversationId
    conversation {
      conversationId
      name
    }
  }
}
`;

const getProfile = gql`
    query getProfile($userId: ID!) {
        getProfile(userId: $userId) {
            userId
            username
        }
    }
`

const getUserAndConversations = gql`
    query getUserAndConversations($userId:ID!) {
        getProfile(userId:$userId) {
            userId
            username
            conversations(limit: 100) {
                items {
                    participantId
                    conversation {
                        conversationId
                        name
                    }
                }
            }
        }
    }
`

const getConversation = gql`
    query getConversation($conversationId: ID!) {
        getConversation(conversationId:$conversationId) {
            conversationId
            name
            associated {
                items {
                    userId
                    user {
                        username
                        Avatar {
                            bucket
                            tinySq
                        }
                    }
                }
            }
            participants
            messages(limit: 100) {
                items {
                    messageId
                    content
                    userId
                    conversationId
                    createdAt
                }
            }
            createdAt
            updatedAt
        }
    }
`

const onCreateMessage = gql`
    subscription onCreateMessage($conversationId: ID!) {
        onCreateMessage(conversationId: $conversationId) {
            messageId
            content
            userId
            conversationId
            createdAt
        }
    }
`

const onCreateUser = gql`subscription OnCreateUser {
    onCreateUser {
        userId
        username
        createdAt
    }
}
`;

export {
    createMessage,
    createConversation,
    createParticipant,
    getConversation,
    getProfile,
    getUserAndConversations,
    onCreateMessage,
    onCreateUser
}
