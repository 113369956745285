import PropTypes from "prop-types";
import React from "react";
import button from '@mui/material/Button';

//const SecondaryButtonAlt = props => <ButtonStyled {...props} children={props.children.split('').reverse()} />

const Button = ({ isLoading, disabled, secondary, children, ...props }) => {
  //const variant = secondary ? SecondaryButton : null;
  return (
    <button
      disabled={isLoading || disabled}
      //as={variant}
      fullWidth={true}
      {...props}
    >
      {isLoading ? "...loading" : children}
    </button>
  );
};

Button.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
  secondary: false,
  children: " ",
  fullWidth: false,
};

export default Button;
