import "./SubscriptionProductsList.css";

import {API, Storage} from "aws-amplify";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import {
    Container,
    Stack,
    Grid,
    FormGroup,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Button
} from "@mui/material";
import {DropzoneArea} from "react-mui-dropzone";
import config from "../config";
import {onError} from "../libs/errorLib";
import {s3UploadUserSubscriptionProductImage} from "../libs/awsLib";
import {useAppContext} from "../libs/contextLib";

export default function SubscriptionProductsList() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isProductOwner, setIsProductOwner] = useState(false);
    const [showCreate, setShowCreate] = useState(false);

    const file = useRef(null);
    const [description, setDescription] = useState("");
    const [subscriptionName, setSubscriptionName] = useState("");
    const [tier, setTier] = useState("");
    const [price, setPrice] = useState(0);
    const [subscriptionLimit, setSubscriptionLimit] = useState(0);
    const [userSubscription, setUserSubscription] = useState(null);

    const {userInfo} = useAppContext();

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state ? location.state : {};

    useEffect(() => {
        async function onLoad() {
            try {
                const products = await loadProducts();
                if (locationState.userId && userInfo.id !== locationState.userId) {
                    const userSubscriptions = await loadSubscriptions(
                        locationState.userId
                    );
                    if (Array.isArray(userSubscriptions)) {
                        let activeSubscriptions = userSubscriptions.filter(
                            (subscription) => subscription.active === true
                        );
                        if (activeSubscriptions.length === 1) {
                            //there can be only one
                            setUserSubscription(activeSubscriptions[0]);
                        }
                    }
                }
                if (products.length > 0) {
                    let productPromises = products.map(async (product) => {
                        if (product.userId === userInfo.id) {
                            setIsProductOwner(true);
                        }
                        product.smallSq = await getImageUrl(
                            product.Image.smallSq,
                            "PUBLIC"
                        );
                    });
                    await Promise.all(productPromises);
                    setProducts(products);
                } else {
                    if (!locationState.userId || userInfo.id === locationState.userId) {
                        setIsProductOwner(true);
                        setShowCreate(true);
                    }
                }
            } catch (e) {
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, []);

    async function loadSubscriptions(id) {
        return API.get("ixie", "user/subscriptions/" + id);
    }

    function loadProducts() {
        if (locationState.userId) {
            return API.get(
                "ixie",
                "userProducts/subscription/" + locationState.userId
            );
        }
        return API.get("ixie", "userProducts/subscription");
    }

    async function getImageUrl(image, groupId) {
        if (image && groupId === "PRIVATE") {
            return await Storage.vault.get(image);
        } else if (image && groupId === "PUBLIC") {
            return await Storage.get(image);
        }
    }

    function validateForm() {
        let valid =
            description.length > 0 &&
            subscriptionName.length > 0 &&
            tier.length > 0 &&
            !isNaN(price) &&
            price >= 0 &&
            ((!isNaN(subscriptionLimit) && subscriptionLimit >= 0) ||
                subscriptionLimit === "");

        return valid;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${
                    config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }

        setIsLoading(true);

        try {
            let product = {};
            if (file.current) {
                const {key} = file.current
                    ? await s3UploadUserSubscriptionProductImage(file.current, userInfo)
                    : null;

                const Image = {
                    original: key,
                    filename: file.current.name,
                    bucket: config.s3.BUCKET,
                };
                product.Image = Image;
            }

            product.subscriptionName = subscriptionName;
            product.tier = tier;
            product.description = description;
            product.price = price;
            product.subscriptionLimit = subscriptionLimit;

            await createProduct(product);
            window.location.reload(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function createProduct(product) {
        return API.post("ixie", "userProducts/subscription", {
            body: product,
        });
    }

    function handleFileChange(selectedFile) {
        if (Array.isArray(selectedFile) && selectedFile.length > 0) {
            file.current = selectedFile[0];
        }
    }

    function handleSubscribeButton(subscriptionId, event) {
        event.preventDefault();
        let product = products.find(
            (prod) => prod.subscriptionId === subscriptionId
        );
        if (product) {
            navigate(
                location.pathname + "/" + product.tier,
                {
                    state: {
                        type: "userSubscriptionProduct",
                        subscriptionId: subscriptionId,
                    },
                });
        }
    }

    function handleDeleteButton(subscriptionId, event) {
        event.preventDefault();
        let result = API.del("ixie", "userProducts/subscription/" + subscriptionId);
        if (result) {
            setProducts(
                products.filter((prod) => prod.subscriptionId !== subscriptionId)
            );
        }
        if (products.length === 0) {
            setShowCreate(true);
        }
    }

    function handleCreateButton() {
        setShowCreate(true);
    }

    function handleCancelButton() {
        setShowCreate(false);
    }

    function renderTierOptions() {
        let options = [];
        options.push(
            <MenuItem key="0" value="">
                {" "}
                -- Select --{" "}
            </MenuItem>
        );
        for (let i = 1; i <= 10; i++) {
            let tierName = "TIER" + (i < 10 ? "0" + i : i);
            let product = products.find((p) => p.tier === tierName);
            if (!product) {
                options.push(
                    <MenuItem key={i} value={tierName}>
                        {tierName}
                    </MenuItem>
                );
            }
        }
        return options;
    }

    function renderCreateButton() {
        return (
            <Container>
                <Stack>
                    <Button variant="link" onClick={handleCreateButton}>
                        Create
                    </Button>
                    {showCreate && (
                        <form onSubmit={handleSubmit}>
                            <Grid>
                                <FormGroup>
                                    <DropzoneArea
                                        id="attachment"
                                        name="Attachment"
                                        label="Attachment"
                                        onChange={handleFileChange}
                                        maxFileSize={config.MAX_ATTACHMENT_SIZE}
                                        filesLimit={1}
                                        dropzoneText="Drag and drop an image here or click"
                                        acceptedFiles={["image/png", "image/jpeg"]}
                                    />
                                </FormGroup>
                                <Container>
                                    <Stack>
                                        <Grid>
                                            <FormControl>
                                                <Select
                                                    name="Tier"
                                                    label="Tier"
                                                    value={tier}
                                                    onChange={(e) => setTier(e.target.value)}
                                                >
                                                    {renderTierOptions()}
                                                </Select>
                                            </FormControl>

                                            <FormGroup>
                                                <TextField
                                                    id="subscriptionName"
                                                    label="Subscription Name"
                                                    name="Subscription Name"
                                                    value={subscriptionName}
                                                    onChange={(e) => setSubscriptionName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid>
                                            <FormGroup>
                                                <TextField
                                                    id="price"
                                                    label="Price"
                                                    name="Price"
                                                    value={price}
                                                    onChange={(e) => setPrice(e.target.value)}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <TextField
                                                    id="subscriptionLimit"
                                                    name="Subscription Limit"
                                                    label="Subscription Limit"
                                                    helperText="0 or blank for unlimited"
                                                    value={subscriptionLimit}
                                                    onChange={(e) => setSubscriptionLimit(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid>
                                            <FormGroup>
                                                <TextField
                                                    id="description"
                                                    label="Description"
                                                    name="Description"
                                                    value={description}
                                                    multiline
                                                    minRows={4}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Stack>
                                </Container>
                            </Grid>
                            <Grid>
                                <span>Cancel</span>
                                <Button
                                    block
                                    type="submit"
                                    size="lg"
                                    variant="primary"
                                    isLoading={isLoading}
                                    disabled={!validateForm()}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </form>
                    )}
                </Stack>
            </Container>
        );
    }

    function renderProductList() {
        return (
            <Stack>
                {products.map((product) => (
                    <Container key={product.subscriptionId}>
                        <Grid>
                            <img src={product.smallSq} className="img-fluid"/>
                            <Container>
                                <Stack>
                                    <Grid>
                                        {isProductOwner && (
                                            <>
                                                <span>{product.tier}</span>
                                                <span> - </span>
                                            </>
                                        )}
                                        <span>{product.subscriptionName}</span>
                                        {product.subscriptionLimit !== undefined && (
                                            <span>
                            Subscription Limit: {product.subscriptionLimit}
                          </span>
                                        )}
                                        <span>{product.price}</span>
                                    </Grid>
                                    <Grid>
                                        <span>{product.description}</span>
                                    </Grid>
                                    {isProductOwner && (
                                        <Grid>
                                            <span>Edit</span>
                                            <Button
                                                onClick={(e) =>
                                                    handleDeleteButton(product.subscriptionId, e)
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    )}
                                    {!isProductOwner && (
                                        <Grid>
                                            {userSubscription &&
                                            userSubscription.userSubscriptionProductId ===
                                            product.subscriptionId ? (
                                                <span>Cancel</span>
                                            ) : userSubscription ? (
                                                <span>Switch Subscription</span>
                                            ) : (

                                                <Button
                                                    onClick={(e) =>
                                                        handleSubscribeButton(product.subscriptionId, e)
                                                    }
                                                >
                                                    Subscribe
                                                </Button>
                                            )}
                                        </Grid>
                                    )}
                                </Stack>
                            </Container>
                        </Grid>
                    </Container>
                ))}
            </Stack>
        );
    }

    return (
        <div className="SubscriptionProductsList">
            {products.length <= 10 && isProductOwner && renderCreateButton()}
            {renderProductList()}
        </div>
    );
}
