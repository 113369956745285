import "./index.css";

import * as Sentry from "@sentry/react";
import * as serviceWorker from "./serviceWorker";

import {AUTH_TYPE, createAuthLink} from "aws-appsync-auth-link";
import {Amplify, Auth} from "aws-amplify";
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client";

import App from "./App";
import {Integrations} from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";
import reportWebVitals from "./reportWebVitals";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {createTheme, ThemeProvider} from '@mui/material/styles';

import "@fontsource/roboto";
import "@fontsource/fjalla-one";
import "@fontsource/material-icons";
import "@fontsource/material-icons-outlined";
import {BrowserRouter} from "react-router-dom";

const ixieTheme = createTheme({
    app: {
        backgroundColor: "#fafafa",
        linkColor: "green",
    },
    navbar: {
        backgroundColor: "#fff",
        borderColor: "#dbdbdb",
    },
    container: {
        maxWidth: "1000px",
        //backgroundColor: "#fff",
    },
    menu: {
        linkColor: "green",
        linkColorActive: "yellow",
        backgroundColor: "#fff",
    },
    buttons: {
        borderRadius: "0px",
        fontFamily: "Fjalla One",
        primary: {
            color: "#fff",
            backgroundColor: "#145A64",
            backgroundColorHover: "#1D383C",
            backgroundColorActive: "#154A52",
        },
        secondary: {
            color: "#fff",
            backgroundColor: "#978357",
            backgroundColorHover: "#4F442C",
            backgroundColorActive: "#7B6D4E",
        },
    },
});

Amplify.configure({
    Auth: {
        //mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: [
            {
                name: "ixie",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
        ],
    },
    aws_appsync_graphqlEndpoint: config.appsync.URL,
    aws_appsync_region: config.appsync.REGION,
    aws_appsync_authenticationType: config.appsync.AUTHTYPE,
});

// const appSyncConfig = {
//     url: config.appsync.URL,
//     region: config.appsync.REGION,
//     auth: {
//         type: AUTH_TYPE[config.appsync.AUTHTYPE],
//         credentials: () => Auth.currentCredentials()
//     }
// };
//
// const appsyncClient = new ApolloClient({
//     link: ApolloLink.from([
//         createAuthLink(appSyncConfig),
//         createSubscriptionHandshakeLink(appSyncConfig)
//     ]),
//     cache: new InMemoryCache(),
//     defaultOptions: {
//         watchQuery: {
//             fetchPolicy: "cache-and-network"
//         }
//     }
// });

const appsyncHttpLink = createHttpLink({uri: config.appsync.URL});

const appsyncLink = ApolloLink.from([
    createAuthLink({
        url: config.appsync.URL,
        region: config.appsync.REGION,
        auth: {
            type: AUTH_TYPE[config.appsync.AUTHTYPE],
            credentials: () => Auth.currentCredentials(),
        },
    }),
    createSubscriptionHandshakeLink(config.appsync.URL, appsyncHttpLink),
]);

const appsyncClient = new ApolloClient({
    link: appsyncLink,
    cache: new InMemoryCache(),
});

const AppWithAppSyncProvider = () => (
    <ApolloProvider client={appsyncClient}>
        <App/>
    </ApolloProvider>
);

Sentry.init({
    dsn:
        "https://d84e7f2075134c8fa8da909ee62c98f5@o524882.ingest.sentry.io/5638085",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={ixieTheme}>
                <BrowserRouter>
                    <AppWithAppSyncProvider/>
                </BrowserRouter>
            </ThemeProvider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
