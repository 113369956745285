import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";
import {API} from "aws-amplify";

export default function SubscriptionForm() {

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [cardName, setCardName] = useState("");

    const location = useLocation();
    const item = location.state;

    const stripe = useStripe();
    const elements = useElements();

    async function handleChange(event) {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }

    async function createPaymentMethod() {
        return await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                name: cardName
            }
        });
    }

    async function createSubscription(paymentMethod) {
        let subscription = await API.post("ixie", "billing/subscription", {
            body: {
                paymentMethodId: paymentMethod.id,
                subscriptionId: item.subscriptionId
            }
        });
        return subscription;
    }

    async function handleSubmit(ev) {
        ev.preventDefault();
        setProcessing(true);


        if (cardName.length > 0) {

            let paymentMethod = await createPaymentMethod();
            if (paymentMethod.error) {
                setError(paymentMethod);
            } else {
                let subscription = await createSubscription(paymentMethod.paymentMethod);
                console.log(subscription);
                setSucceeded(true);

            }

        }

        setProcessing(false);

    }


    return (
        <form
            id="subscription-form"
            onSubmit={handleSubmit}>
            <FormGroup>
                <TextField
                    id="cardName"
                    name="Name"
                    label="Name on Credit Card"
                    type="text"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                />
            </FormGroup>
            <CardElement id="card-element" onChange={handleChange}/>
            <Button
                disabled={processing || disabled || succeeded}
                type="submit"
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      "Subscribe"
                  )}
                </span>
            </Button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            {succeeded && (
                <p className="result-message">
                    Subscription succeeded.
                </p>
            )}
        </form>
    );
}
