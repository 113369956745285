import React from "react";
import "./NotFound.css";
//import {useLocation} from "react-router-dom";

export default function NotFound() {

    //const location = useLocation();
    //console.log("location info :: " + JSON.stringify(location));

    return (
        <div className="NotFound text-center">
            <h3>Sorry, page not found!</h3>
        </div>
    );
}
