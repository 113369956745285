import "./Conversation.css";

import * as uuid from "uuid";

//import { ChatFeed, Message } from "react-chat-ui";
import React, {useEffect, useState} from "react";
import {
    createMessage,
    getConversation,
    onCreateMessage,
} from "../libs/graphqlLib";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription,
} from "@apollo/client";

import {API} from "aws-amplify";
import {BsSearch} from "react-icons/bs";
import {
    FormGroup,
    FormHelperText,
    TextField,
    Button, Stack
} from "@mui/material";
import {Link} from "react-router-dom";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";

export default function Conversation() {
    const [subSkip, setSubSkip] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isTyping, setIsTyping] = useState(false);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [newMessages, setNewMessages] = useState([]);
    const [conversation, setConversation] = useState({});

    const location = useLocation();
    const conversationInfo = location.state;

    const {userInfo} = useAppContext();

    const [loadConversation, getConversationResult] = useLazyQuery(
        getConversation,
        {
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => setAndParseConversation(data),
        }
    );

    const [sendMessage, createMessageResult] = useMutation(createMessage);

    const messageSubscription = useSubscription(onCreateMessage, {
        variables: {conversationId: conversation.conversationId},
        skip: subSkip,
    });

    useEffect(() => {
        async function onLoad() {
            try {
                let conversationId = conversationInfo.conversationId;
                if (!conversationId && conversationInfo.userId) {
                    conversationId = await getConversationId(conversationInfo.userId);
                }
                if (conversationId) {
                    loadConversation({variables: {conversationId}});
                    // let conversation = conversationResult.data.getConversation;
                    // setConversation(conversation);
                    // console.log(conversation);
                    // if (conversation) {
                    //     parseMessages(conversation.messages);
                    //     LatestMessage(conversationId);
                    // }
                }
            } catch (e) {
                onError(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(subscription);
        if (messageSubscription.data) {
            //setNewMessages([subscription.data.onCreateMessage]);
            parseMessages([messageSubscription.data.onCreateMessage]);
        }
        //parseMessages(newMessages);
    }, [messageSubscription.data]);

    function setAndParseConversation(data) {
        console.log(data);
        let conversation = data.getConversation;
        setConversation(conversation);
        parseMessages(conversation.messages.items);
        setSubSkip(false);
    }

    // function LatestMessage() {
    //     let subscription = useSubscription(onCreateMessage, {
    //         variables: {conversationId: conversation.conversationId},
    //         skip: subSkip
    //     });
    //     //console.log(subscription);
    //     if (subscription.data) {
    //         setNewMessages([subscription.data.onCreateMessage]);
    //     }
    //     return null;
    // }

    async function getConversationId(userId) {
        return API.get("ixie", `conversation/find/${userId}`);
    }

    function parseMessages(newMessages) {
        //console.log("parsing: " + JSON.stringify(newMessages));
        let parsedMessages = [];
        // newMessages.forEach((message) => {
        //   parsedMessages.push(
        //     new Message({
        //       id: message.userId === userInfo.id ? 0 : 1,
        //       message: message.content,
        //     })
        //   );
        // });
        //
        // setMessages(messages.concat(parsedMessages));
    }

    function validateMessageForm() {
        return message.length > 0;
    }

    async function handleMessageSubmit(event) {
        event.preventDefault();

        sendMessage({
            variables: {
                messageId: uuid.v4(),
                userId: userInfo.id,
                conversationId: conversation.conversationId,
                content: message,
                createdAt: Date.now(),
            },
        });

        setMessage("");
    }

    function renderMessageForm() {
        return (
            <form onSubmit={handleMessageSubmit}>
                <FormGroup>
                    <TextField
                        id="message"
                        name="Message"
                        label="Message"
                        type="text"
                        value={message}
                        autoComplete="off"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        block
                        size="lg"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateMessageForm()}
                    >
                        Send
                    </Button>
                </FormGroup>
            </form>
        );
    }

    function renderChat() {
        return (
            <p>yeah... this needs work</p>
        );
        // return (
        //   <ChatFeed
        //     messages={messages}
        //     isTypeing={isTyping}
        //     hasInputField={false}
        //     bubblesCentered={false}
        //   />
        // );
    }

    function renderConversation() {
        return (
            <Stack>
                <h2>{conversation && conversation.name}</h2>
                {conversation && renderChat()}
                {renderMessageForm()}
            </Stack>
        );
    }

    return renderConversation();
}
