import "./Settings.css";

import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {Button, Stack} from "@mui/material";

export default function Settings() {

    return (
        <Stack>
            <h1>Settings</h1>
            <Button component={Link} to="/settings/subscriptionproducts">
                Create/Edit subscriptions to your content
            </Button>
        </Stack>
    );
}
