import "./ChangePassword.css";

import React, { useState } from "react";

import { Auth } from "aws-amplify";
import {
  FormGroup,
  FormHelperText,
  TextField,
  Button
} from "@mui/material";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [isChanging, setIsChanging] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );

      navigate("/profile");
    } catch (error) {
      onError(error);
      setIsChanging(false);
    }
  }

  return (
    <div className="ChangePassword">
      <form onSubmit={handleChangeClick}>
        <FormGroup>
          <TextField
              label="Old Password"
              id="oldPassword"
              name="Old Password"
            type="password"
            onChange={handleFieldChange}
            value={fields.oldPassword}
          />
        </FormGroup>
        <hr />
        <FormGroup>
          <TextField
              label="New Password"
              id="password"
              name="New Password"
            type="password"
            onChange={handleFieldChange}
            value={fields.password}
          />
        </FormGroup>
        <FormGroup>
          <TextField
              label="Confirm Password"
              id="confirmPassword"
              name="Confirm Password"
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <Button
          block
          type="submit"
          size="lg"
          disabled={!validateForm()}
          isLoading={isChanging}
        >
          Change Password
        </Button>
      </form>
    </div>
  );
}
