import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = ({ message, align = "left", ...props }) => {
  return (
    <Box align={align} sx={{ display: 'flex' }}>
      <CircularProgress />
      {message && <Box {...props}>{message}</Box>}
    </Box>
  );
};

export default Loading;
