import React, {useState, useEffect} from "react";
import config from "../config";
import "./Tip.css";
import {loadStripe} from "@stripe/stripe-js";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import SubscriptionForm from "../components/SubscriptionForm";

export default function Subscribe() {

    const stripePromise = loadStripe(config.STRIPE_KEY);

    return (
        <Elements stripe={stripePromise}>
            <div className="Subscribe">
                <SubscriptionForm />
            </div>
        </Elements>
    );

}
