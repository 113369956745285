import React, {useState, useEffect} from "react";
import {API} from "aws-amplify";
import {
    CardElement,
    useStripe,
    useElements,
    PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import {useLocation} from "react-router-dom";
import {
    FormControlLabel,
    Checkbox,
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";

export default function CheckoutForm() {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [saveCard, setSaveCard] = useState(true);
    const [paymentRequest, setPaymentRequest] = useState(null);

    const stripe = useStripe();
    const elements = useElements();

    const location = useLocation();

    const item = location.state;

    useEffect(() => {

        API.post("ixie", "billing/otpIntent", {
            body: {
                items: [
                    {
                        id: "tip",
                        amount: item.amount,
                        postId: item.postId
                    }
                ]
            }
        }).then(data => {
            setClientSecret(data.clientSecret);
        });

    }, [stripe]);

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        let data = {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        };
        if (saveCard) {
            data.setup_future_usage = "off_session";
        }

        const payload = await stripe.confirmCardPayment(clientSecret, data);
        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {paymentRequest && (
                <PaymentRequestButtonElement options={{paymentRequest}}/>
            )}
            <CardElement id="card-element" onChange={handleChange}/>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox
                        defaultChecked={saveCard}
                        onChange={(e) => setSaveCard(e.target.checked)}
                    />}
                    label="Save for future purchases?"
                />
            </FormGroup>
            <button
                disabled={processing || disabled || succeeded}
                id="submit"
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      "Pay now"
                  )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            {succeeded && (
                <p className="result-message">
                    Payment succeeded.
                </p>
            )}
        </form>
    );

}
