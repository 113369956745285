import "./ChangeEmail.css";

import { API, Auth } from "aws-amplify";
import React, { useState } from "react";

import {
  FormGroup,
  FormHelperText,
  TextField,
  Button
} from "@mui/material";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";

export default function ChangeUsername() {
  const [userInfo, setUserInfo] = useState(null);

  const [fields, handleFieldChange] = useFormFields({
    username:
      userInfo && userInfo.attributes && userInfo.attributes.preferred_username
        ? userInfo.attributes.preferred_username
        : "",
  });

  function validateUsernameForm() {
    return (
      fields.username.length > 0 && /^[0-9a-zA-Z_.-]+$/.test(fields.username)
    );
  }

  async function updateUsername(username) {
    return API.post("ixie", "user", {
      body: { username },
    });
  }

  async function handleUpdateClick(event) {
    event.preventDefault();

    try {
      //going to end up moving this to a lambda because we can't depend on client validation

      await updateUsername(fields.username);
      let tempInfo = await Auth.currentUserInfo();
      console.log({ userInfo: tempInfo });
      setUserInfo(tempInfo);
    } catch (error) {
      onError(error);
    }
  }

  function renderUpdateForm() {
    return (
      <form onSubmit={handleUpdateClick}>
        <FormGroup>
          <TextField
              name="Username"
              label="Username"
              id="username"
            type="text"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <Button
          block
          type="submit"
          size="lg"
          disabled={!validateUsernameForm()}
        >
          Update Username
        </Button>
      </form>
    );
  }

  return <div className="ChangeUsername">{renderUpdateForm()}</div>;
}
