import "./NewPost.css";

import React, {useEffect, useRef, useState} from "react";

import {API} from "aws-amplify";
import {
    FormGroup,
    FormHelperText,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";
import {DropzoneArea} from "react-mui-dropzone";
import config from "../config";
import {onError} from "../libs/errorLib";
import {s3Upload} from "../libs/awsLib";
import {useAppContext} from "../libs/contextLib";
import {useNavigate} from "react-router-dom";

export default function NewPost() {
    const file = useRef(null);
    const navigate = useNavigate();
    const [content, setContent] = useState("");
    const [publicFlag, setPublicFlag] = useState(true);
    const [tier, setTier] = useState(0);
    const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const {userInfo} = useAppContext();

    useEffect(() => {
        async function onLoad() {
            try {
                const availableSubscriptions = await loadAvailableSubscriptions();

                availableSubscriptions.sort((a, b) => {
                    if (a.tier < b.tier) {
                        return -1;
                    } else if (a.tier > b.tier) {
                        return 1;
                    }
                    return 0;
                });

                if (
                    Array.isArray(availableSubscriptions) &&
                    availableSubscriptions.length > 0
                ) {
                    setAvailableSubscriptions(availableSubscriptions);
                }
            } catch (e) {
                //onError(e);
            }
        }

        onLoad();
    }, []);

    function validateForm() {
        return content.length > 0;
    }

    function handleFileChange(selectedFile) {
        console.log(selectedFile);
        if (Array.isArray(selectedFile) && selectedFile.length > 0) {
            file.current = selectedFile[0];
        }
        console.log(file);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${
                    config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }

        setIsLoading(true);

        try {
            const {key, groupKey, dateKey} = file.current
                ? await s3Upload(file.current, userInfo, publicFlag)
                : null;

            const groupId = publicFlag ? "PUBLIC" : "PRIVATE";

            const File = {
                original: key,
                filename: file.current.name,
                groupKey,
                dateKey,
                bucket: config.s3.BUCKET,
            };

            await createPost({content, File, groupId, tier});
            navigate("/");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function createPost(content) {
        return API.post("ixie", "posts", {
            body: content,
        });
    }

    function renderTierOptions() {
        let options = [];

        availableSubscriptions.forEach((sub) => {
            let tierName = sub.tier;
            options.push(
                <MenuItem key={tierName} value={parseInt(tierName.slice(-2))}>
                    {tierName}
                </MenuItem>
            );
        });
        return (
            <>
                {options.map((option) => {
                    return option;
                })}
            </>
        );
    }

    async function loadAvailableSubscriptions() {
        return API.get("ixie", "userProducts/subscription");
    }

    return (
        <div className="NewPost">
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <TextField
                        id="content"
                        label="Content"
                        name="Content"
                        multiline
                        minRows={4}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </FormGroup>
                <FormGroup controlId="file">
                    <DropzoneArea
                        id="attachment"
                        name="Attachment"
                        label="Attachment"
                        onChange={handleFileChange}
                        maxFileSize={config.MAX_ATTACHMENT_SIZE}
                        filesLimit={1}
                        dropzoneText="Drag and drop a file here or click"
                        acceptedFiles={["image/*", "video/*"]}
                    />
                </FormGroup>
                {availableSubscriptions.length > 0 && (
                    <>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    defaultChecked={publicFlag}
                                    onChange={(e) => setPublicFlag(e.target.checked)}
                                />}
                                label="Public?"
                            />
                        </FormGroup>
                        {!publicFlag && (
                            <FormControl>
                                <Select
                                    id="tier"
                                    name="Tier"
                                    label="Make available to this tier and above"
                                    value={tier}
                                    onChange={(e) => setTier(e.target.value)}
                                >
                                    {renderTierOptions()}
                                </Select>
                            </FormControl>
                        )}
                    </>
                )}
                <Button
                    block
                    type="submit"
                    size="lg"
                    variant="primary"
                    isLoading={isLoading}
                    disabled={isLoading || !validateForm()}
                >
                    Create
                </Button>
            </form>
        </div>
    );
}
