import "./Signup.css";

import {API, Auth} from "aws-amplify";
import React, {useState} from "react";

import {
    FormGroup,
    FormHelperText,
    TextField,
    Button
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";
import {useNavigate} from "react-router-dom";
import {v4 as uuid4} from "uuid";

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        nameInput: "",
        usernameInput: "",
        birthdateInput: null,
        emailInput: "",
        passwordInput: "",
        confirmPasswordInput: "",
        confirmationCodeInput: "",
    });
    const navigate = useNavigate();
    const [cogUsername, setCogUsername] = useState(null);
    const [newUser, setNewUser] = useState(null);
    const {userHasAuthenticated, setUserInfo} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState([]);

    function handleBirthdateFieldChange(value) {
        handleFieldChange({
            target: {
                id: "birthdateInput",
                value,
            },
        });
    }

    function handlePasswordFieldChange(event) {
        if (event.target.value !== fields.passwordInput) {
            setPasswordValid(validPassword(event.target.value));
        }
        handleFieldChange(event);
    }

    function validateForm() {

        // console.log({
        //     "fields.nameInput.length > 0": fields.nameInput.length > 0,
        //     "fields.usernameInput.length > 0": fields.usernameInput.length > 0,
        //     "/^[0-9a-zA-Z_.-]+$/.test(fields.usernameInput)": /^[0-9a-zA-Z_.-]+$/.test(fields.usernameInput),
        //     "fields.birthdateInput.isValid()": fields.birthdateInput ? fields.birthdateInput.isValid() : null,
        //     "fields.emailInput.length > 0": fields.emailInput.length > 0,
        //     "fields.passwordInput.length > 0": fields.passwordInput.length > 0,
        //     "passwordValid": passwordValid,
        //     "fields.passwordInput === fields.confirmPasswordInput": fields.passwordInput === fields.confirmPasswordInput
        // });

        //console.log(fields.birthdateInput);

        let valid =
            fields.nameInput.length > 0 &&
            fields.usernameInput.length > 0 &&
            /^[0-9a-zA-Z_.-]+$/.test(fields.usernameInput) &&
            (fields.birthdateInput ? fields.birthdateInput.isValid() : false) &&
            fields.emailInput.length > 0 &&
            fields.passwordInput.length > 0 &&
            passwordValid &&
            fields.passwordInput === fields.confirmPasswordInput;

        //console.log(valid);

        return valid;
    }

    function validPassword(value) {
        let errors = [];
        if (value.length < 8) {
            errors.push("must be 8 or more characters");
        }
        if (value.length > 99) {
            errors.push("must be 99 or less characters");
        }
        if (value.length === 0 || value.search("[/a-z/]") < 0) {
            errors.push("must include at least one lower case character");
        }
        if (value.length === 0 || value.search("[/A-Z/]") < 0) {
            errors.push("must include at least one upper case character");
        }
        if (value.length === 0 || value.search("[/0-9/]") < 0) {
            errors.push("must include at least one number");
        }
        if (
            value.length === 0 ||
            value.search("[/\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&\\/,><\\’:;|_~`/]") < 0
        ) {
            errors.push("must include at least one special character");
        }
        if (errors.length > 0) {
            if (errors.length !== passwordValidation.length) {
                setPasswordValidation(errors);
            }
            return false;
        }
        setPasswordValidation([]);
        return true;
    }

    function validateConfirmationForm() {
        return fields.confirmationCodeInput.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {

            let tempUsername = null;
            if (!cogUsername) {
                tempUsername = uuid4();
                setCogUsername(tempUsername);
            } else {
                tempUsername = cogUsername;
            }

            console.log("cogUsername === " + cogUsername);
            console.log("tempUsername === " + tempUsername);

            const newUser = await Auth.signUp({
                username: tempUsername,
                password: fields.passwordInput,
                attributes: {
                    name: fields.nameInput,
                    email: fields.emailInput.toLowerCase(),
                    birthdate: fields.birthdateInput.format("L"),
                    "custom:signup_username": fields.usernameInput.toLowerCase(),
                },
            });
            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.confirmSignUp(cogUsername, fields.confirmationCodeInput);
            await Auth.signIn(fields.emailInput, fields.passwordInput);

            let UserInfo = await Auth.currentUserInfo();
            console.log({userInfo: UserInfo});
            if (
                !UserInfo.attributes["custom:has_profile"] ||
                UserInfo.attributes["custom:has_profile"] != 1
            ) {
                console.log("making profile");
                let result = await API.get("ixie", "profile/create");
                if (result) {
                    UserInfo.attributes["custom:has_profile"] = "1";
                }
            }
            setUserInfo(UserInfo);

            userHasAuthenticated(true);
            navigate("/");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmationSubmit}>
                <FormGroup>
                    <TextField
                        id="confirmationCodeInput"
                        label="Confirmation Code"
                        name="Name"
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCodeInput}
                    />
                    <FormHelperText>Please check your email for the code.</FormHelperText>
                </FormGroup>
                <Button
                    block
                    size="lg"
                    type="submit"
                    variant="success"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Verify
                </Button>
            </form>
        );
    }

    function renderForm() {
        return (
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <TextField
                        id="nameInput"
                        label="Name"
                        name="Name"
                        type="text"
                        value={fields.nameInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        id="usernameInput"
                        name="Username"
                        label="Username"
                        type="text"
                        value={fields.usernameInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup>
                    <DatePicker
                        id="birthdateInput"
                        label="Birthdate"
                        disableFuture={true}
                        value={fields.birthdateInput}
                        onChange={handleBirthdateFieldChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        id="emailInput"
                        label="Email"
                        name="Email"
                        type="email"
                        value={fields.emailInput}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        id="passwordInput"
                        label="Password"
                        name="Password"
                        type="password"
                        value={fields.passwordInput}
                        onChange={handlePasswordFieldChange}
                    />

                    {passwordValidation.length > 0 && (
                        <FormHelperText>{
                            passwordValidation.map(line => {
                                return (<>{line} <br/></>);
                            })
                        }</FormHelperText>
                    )}
                </FormGroup>
                <FormGroup>

                    <TextField
                        id="confirmPasswordInput"
                        label="Confirm Password"
                        name="Confirm Password"
                        type="password"
                        onChange={handleFieldChange}
                        value={fields.confirmPasswordInput}
                    />
                </FormGroup>
                <Button
                    block
                    size="lg"
                    type="submit"
                    variant="success"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Signup
                </Button>
            </form>
        );
    }

    return (
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
}
