import "./ProfileEdit.css";

import {API, Storage} from "aws-amplify";
import {
    Container,
    Stack,
    Grid,
    FormGroup,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    TextField,
    Button
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";

import config from "../config";
import {onError} from "../libs/errorLib";
import {s3UploadAvatar} from "../libs/awsLib";
import {useAppContext} from "../libs/contextLib";
import {useNavigate} from "react-router-dom";
import {DropzoneArea} from "react-mui-dropzone";

export default function ProfileEdit() {
    const file = useRef(null);
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {userInfo} = useAppContext();

    useEffect(() => {
        async function onLoad() {
            try {
                const profile = await loadProfile();
                if (profile && profile.Avatar && profile.Avatar.smallSq) {
                    profile.smallSq = await getImageUrl(profile.Avatar.smallSq, "PUBLIC");
                }
                setProfile(profile);
                setContent(profile.profile);

                //console.log("profile: " + JSON.stringify(profile));
            } catch (e) {
                if (e.message === "Item not found.") {
                    setProfile({});
                } else {
                    //onError(e);
                }
                //console.log(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, []);

    function validateForm() {
        return content.length > 0;
    }

    function handleFileChange(selectedFile) {
        if (Array.isArray(selectedFile) && selectedFile.length > 0) {
            file.current = selectedFile[0];
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${
                    config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }

        setIsLoading(true);

        try {
            let profile = {};
            if (file.current) {
                const {key} = file.current
                    ? await s3UploadAvatar(file.current, userInfo)
                    : null;

                const Image = {
                    original: key,
                    filename: file.current.name,
                    bucket: config.s3.BUCKET,
                };
                profile.Image = Image;
            }

            profile.profile = content;

            await updateProfile(profile);
            navigate("/profile");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    function loadProfile() {
        return API.get("ixie", "profile/" + userInfo.id);
    }

    function updateProfile(content) {
        return API.post("ixie", "profile", {
            body: content,
        });
    }

    async function getImageUrl(image, groupId) {
        if (image && groupId === "PRIVATE") {
            return await Storage.vault.get(image);
        } else if (image && groupId === "PUBLIC") {
            return await Storage.get(image);
        }
    }

    return (
        <div className="ProfileEdit">
            <form onSubmit={handleSubmit}>
                <Stack>
                    <Grid>
                        <span>{userInfo.attributes.preferred_username}</span>
                        {profile && profile.smallSq && (
                            <img src={profile.smallSq} className="img-fluid"/>
                        )}
                        <FormGroup controlId="file">
                            <DropzoneArea
                                id="attachment"
                                name="Attachment"
                                label="Attachment"
                                onChange={handleFileChange}
                                maxFileSize={config.MAX_ATTACHMENT_SIZE}
                                filesLimit={1}
                                dropzoneText="Drag and drop a file here or click"
                                acceptedFiles={["image/jpeg", "image/png"]}
                            />
                        </FormGroup>
                        <FormGroup controlId="content">
                            <TextField
                                multiline
                                minRows={4}
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                        </FormGroup>
                    </Grid>

                    <Button
                        block
                        type="submit"
                        size="lg"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Update
                    </Button>
                </Stack>
            </form>
        </div>
    );
}
