import { API, Auth } from "aws-amplify";

import React, { useEffect, useState } from "react";

import { AppContext } from "./libs/contextLib";
import Container from "./components/Container";

import Navbar from "./components/Navbar";
import ReactGA from "react-ga"; //I'm not sure if this tracking crap iw working
import AppRoutes from "./AppRoutes";
import { createBrowserHistory } from "history";
import { onError } from "./libs/errorLib";
import { useNavigate } from "react-router-dom";
import {loadProfile} from "./libs/awsLib";

const gaTrackingId = "G-092HHNCH3E";
ReactGA.initialize(gaTrackingId);

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {

      console.log({env: process.env});

      await Auth.currentSession();
      let UserInfo = await Auth.currentUserInfo();
      console.log({ userInfo: UserInfo });
      if (
        !UserInfo.attributes["custom:has_profile"] ||
        UserInfo.attributes["custom:has_profile"] != 1
      ) {
        console.log("making profile");
        let result = await API.get("ixie", "profile/create");
        if (result) {
          UserInfo.attributes["custom:has_profile"] = "1";
        }
      }
      setUserInfo(UserInfo);
      if (UserInfo.id) {
        ReactGA.set({
          userId: UserInfo.id,
        });
      }
      userHasAuthenticated(true);

      let Profile = await loadProfile(UserInfo);
      console.log("Profile: " + Profile);
      setProfile(Profile);
    } catch (e) {
      console.log(e);
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout(e) {
    e.preventDefault();
    await Auth.signOut();

    userHasAuthenticated(false);
    setUserInfo(null);

    navigate("/");
  }

  return (
    !isAuthenticating && (
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            userInfo,
            setUserInfo,
            profile,
            setProfile,
            handleLogout
          }}
        >
          <Navbar>

            {/*          <Dropdown selector={<IconAccount size={24} />}>*/}
            {/*            <Menu>*/}
            {/*              <MenuItem*/}
            {/*                key="account"*/}
            {/*                //key={a.ArtistID}*/}
            {/*                //selected={a.ArtistID === attractionId}*/}
            {/*                //onClick={() => this.handleFilterEvents(a)}*/}
            {/*              >*/}
            {/*                <Link to="/profile">Account</Link>*/}
            {/*              </MenuItem>*/}
            {/*              <MenuItem>*/}
            {/*                <a onClick={(e) => handleLogout(e)}>Logout</a>*/}
            {/*              </MenuItem>*/}
            {/*            </Menu>*/}
            {/*          </Dropdown>*/}

          </Navbar>
            <Container>
              <AppRoutes />
            </Container>
        </AppContext.Provider>
    )
  );
}

export default App;
