import React from "react";
import {styled} from "@mui/material/styles";
import container from "@mui/material/Container"

const ContainerStyled = styled(container)(({theme}) => ({
    margin: "0 auto",
    padding: 0,
    maxWidth: theme.container.maxWidth,
    backgroundColor: theme.container.backgroundColor,
    display: "flex",
    height: "100%",
}));


const Container = ({children, ...props}) => {
    return <ContainerStyled {...props}>{children}</ContainerStyled>;
};
export default Container;
