import "./Posts.css";

import {API, Auth, Storage} from "aws-amplify";
import {
    Container,
    FormGroup,
    FormControl,
    FormHelperText,
    Link as MUILink,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Badge,
    Button,
    ButtonGroup,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {
    BsBookmark,
    BsBookmarkFill,
    BsCaretDown,
    BsCaretDownFill,
    BsCaretUp,
    BsCaretUpFill,
    BsCreditCard,
    BsCursor,
    BsPencilSquare,
} from "react-icons/bs";
import React, {useEffect, useRef, useState} from "react";
import {s3Delete, s3Upload} from "../libs/awsLib";
import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import reactStringReplace from "react-string-replace";

import config from "../config";
import {onError} from "../libs/errorLib";
import {useAppContext} from "../libs/contextLib";

export default function Posts() {
    const file = useRef(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [rating, setRating] = useState();
    const [ratings, setRatings] = useState();
    const [bookmark, setBookmark] = useState(false);
    const [comment, setComment] = useState("");
    const [tip, setTip] = useState("");
    const [comments, setComments] = useState("");
    const [content, setContent] = useState("");
    //const [publicFlag, setPublicFlag] = useState(true); //can't currently switch it

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [showTip, setShowTip] = useState(false);

    const {userInfo, isAuthenticated} = useAppContext();

    async function loadRating() {
        return API.get("ixie", `postRating/${id}`);
    }

    async function loadBookmark() {
        return API.get("ixie", `postBookmark/${id}`);
    }

    async function loadRatings() {
        return API.get("ixie", `postRatings/${id}`);
    }

    async function loadComments() {
        return API.get("ixie", `postComments/${id}`);
    }

    function addLinks(text) {

        let replacedText

        // Match URLs
        replacedText = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
            <MUILink key={match + i} href={match}>{match}</MUILink>
        ));

        // Match @-mentions
        replacedText = reactStringReplace(replacedText, /@(\w+)/g, (match, i) => (
            <MUILink key={match + i} component={Link} to={`/${match}`}>@{match}</MUILink>
        ));

        // Match hashtags
        replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
            <MUILink key={match + i} component={Link} to={`/tags/${match}`}>#{match}</MUILink>
        ));

        return replacedText;

    }

    useEffect(() => {
        function loadPost() {
            return API.get("ixie", `posts/${id}`);
        }

        async function onLoad() {
            try {
                const post = await loadPost();
                const {content, Image} = post;

                if (Image && post.groupId === "PRIVATE") {
                    post.originalURL = Image.original
                        ? await Storage.vault.get(Image.original)
                        : null;
                    post.smallSq = await getSubscriptionImageUrl(post.postId, "smallSq");
                    post.mediumSq = await getSubscriptionImageUrl(
                        post.postId,
                        "mediumSq"
                    );
                    post.largeSq = await getSubscriptionImageUrl(post.postId, "largeSq");
                    post.large = await getSubscriptionImageUrl(post.postId, "large");
                } else if (Image && post.groupId === "PUBLIC") {
                    post.originalURL = Image.original
                        ? await Storage.get(Image.original)
                        : null;
                    post.smallSq = Image.smallSq
                        ? await Storage.get(Image.smallSq.key)
                        : null;
                    post.mediumSq = Image.mediumSq
                        ? await Storage.get(Image.mediumSq.key)
                        : null;
                    post.largeSq = Image.largeSq
                        ? await Storage.get(Image.largeSq.key)
                        : null;
                    post.large = Image.large ? await Storage.get(Image.large.key) : null;
                }

                setContent(content);
                setPost(post);

                if (isAuthenticated) {
                    setRating(await loadRating(post.postId));
                    setBookmark(await loadBookmark(post.postId));
                }
                setRatings(await loadRatings(post.postId));

                let commentsResult = await loadComments(post.postId);


                setComments(commentsResult.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)));
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [id, updateCount]);

    async function getSubscriptionImageUrl(postId, size) {
        return API.get("ixie", "subscription/getimageurl/" + postId + "/" + size);
    }

    function validateForm() {
        return content.length > 0;
    }

    function validateCommentForm() {
        return comment.length > 0;
    }

    function validateTipForm() {
        return tip.length > 0 && !isNaN(tip);
    }

    function formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    function getDetectedLabelsString(post) {
        let threshold = 75;
        let labels = [];
        if (post.DetectedInfo && post.DetectedInfo.labels) {
            post.DetectedInfo.labels.Labels.forEach((label) => {
                if (label.Confidence > threshold) {
                    labels.push(label.Name);
                }
            });
        }
        return labels.join(", ");
    }

    function getDetectedModLabelsString(post) {
        let threshold = 75;
        let labels = [];
        if (post.DetectedInfo && post.DetectedInfo.modLabels) {
            post.DetectedInfo.modLabels.ModerationLabels.forEach((label) => {
                if (label.Confidence > threshold) {
                    labels.push(label.Name);
                }
            });
        }
        return labels.join(", ");
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    function savePost(post) {
        return API.put("ixie", `posts/${id}`, {
            body: post,
        });
    }

    function saveComment(comment) {
        return API.post("ixie", `postComment`, {
            body: {
                postId: post.postId,
                comment,
            },
        });
    }

    async function handleTipButton() {
        showTip ? setShowTip(false) : setShowTip(true);
    }

    async function handleSubmit(event) {
        let attachment;

        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${
                    config.MAX_ATTACHMENT_SIZE / 1000000
                } MB.`
            );
            return;
        }

        setIsLoading(true);

        try {
            let flag = post.groupId === "PUBLIC";
            // if (file.current) {
            //     attachment = await s3Upload(file.current, userInfo, flag);
            // }

            let response = await savePost({
                content,
                //attachment: attachment || post.attachment
            });
            //console.log(response);
            setUpdateCount(updateCount + 1);
            setIsLoading(false);
            setIsEditing(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function handleCommentSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            let response = await saveComment(comment);
            //console.log(response);
            setComment("");
            setUpdateCount(updateCount + 1);
            setIsLoading(false);
            setIsEditing(false);
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function deletePost() {
        let flag = post.groupId === "PUBLIC";
        if (post.Image) {
            //this part should probably be moved to the service
            let images = Object.getOwnPropertyNames(post.Image);
            let delPromises = images.map(async (key) => {
                if (
                    key !== "groupKey" &&
                    key !== "bucket" &&
                    key !== "dateKey" &&
                    key !== "metadata" &&
                    key !== "filename"
                ) {
                    await s3Delete(post.Image[key], flag);
                }
            });
            let s3Responses = await Promise.all(delPromises);
        }
        return API.del("ixie", `posts/${id}`);
    }

    function handleEditButton(event) {
        event.preventDefault();
        setIsEditing(true);
    }

    function handleCancel(event) {
        event.preventDefault();
        setIsEditing(false);
    }

    async function handleDelete(event) {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this post?"
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(true);

        try {
            await deletePost();
            navigate("/");
        } catch (e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    async function handleBookmarkButton(event) {
        if (isAuthenticated) {
            await API.post("ixie", `postBookmark/${id}`, {
                body: {},
            });
            setBookmark(await loadBookmark(post.postId));
        }
    }

    async function handleUpButton(event) {
        if (isAuthenticated) {
            const newRating = rating && rating.rating === 1 ? 0 : 1;
            await API.post("ixie", `postRating/${id}`, {
                body: {
                    rating: newRating,
                    publishedAt: post.publishedAt,
                },
            });
            setRating(await loadRating(post.postId));
            setRatings(await loadRatings(post.postId));
        }
    }

    async function handleDownButton(event) {
        if (isAuthenticated) {
            const newRating = rating && rating.rating === -1 ? 0 : -1;
            await API.post("ixie", `postRating/${id}`, {
                body: {
                    rating: newRating,
                    publishedAt: post.publishedAt,
                },
            });
            setRating(await loadRating(post.postId));
            setRatings(await loadRatings(post.postId));
        }
    }

    async function handleTipSubmit(event) {
        event.preventDefault();

        if (isAuthenticated) {
            navigate(
                "/tip",
                {
                    state: {
                        type: "tip",
                        postId: post.postId,
                        amount: tip,
                    }
                });
        }
    }

    function gotoProfile(username, userId, event) {
        event.preventDefault();
        navigate(
            "/" + (username ? username : userId),
            {
                state: {
                    userId: userId,
                }
            });
    }

    function renderEditing(post) {
        return (
            <Stack>
                <img src={post.large}/>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <TextField
                            id="content"
                            name="Content"
                            label="Content"
                            multiline
                            minRows={4}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </FormGroup>
                    {/*<Form.Group controlId="file">*/}
                    {/*    <Form.Label>Attachment</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        onChange={handleFileChange}*/}
                    {/*        type="file"*/}
                    {/*        accept="image/png,image/jpeg"*/}
                    {/*    />*/}
                    {/*</Form.Group>*/}
                    <Button
                        block
                        size="lg"
                        type="submit"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Save
                    </Button>
                    <Button
                        block
                        size="lg"
                        onClick={handleCancel}
                        isLoading={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        size="lg"
                        variant="danger"
                        onClick={handleDelete}
                        isLoading={isDeleting}
                    >
                        Delete
                    </Button>
                </form>
                {renderDebug(post)}
            </Stack>
        );
    }

    function renderView(post) {
        return (
            <Stack>
                <img src={post.large}/>
                <Toolbar>
                    <ButtonGroup>
                        <Button onClick={handleUpButton}>
                            {rating && rating.rating === 1 ? (
                                <BsCaretUpFill/>
                            ) : (
                                <BsCaretUp/>
                            )}
                            {ratings && <Badge>{ratings.likes}</Badge>}
                        </Button>
                        <Button onClick={handleDownButton}>
                            {rating && rating.rating === -1 ? (
                                <BsCaretDownFill/>
                            ) : (
                                <BsCaretDown/>
                            )}
                            {ratings && <Badge>{ratings.dislikes}</Badge>}
                        </Button>
                        {isAuthenticated && (
                            <>
                                <Button onClick={handleBookmarkButton}>
                                    {bookmark ? <BsBookmarkFill/> : <BsBookmark/>}
                                </Button>
                                <Button>
                                    <BsCursor/>
                                </Button>
                            </>
                        )}
                    </ButtonGroup>
                    <ButtonGroup>
                        {isAuthenticated && (
                            <>
                                <Button onClick={handleTipButton}>
                                    <BsCreditCard/>
                                </Button>
                                <div>
                                    {showTip && (
                                        <form onSubmit={handleTipSubmit}>
                                            <FormGroup controlId="tip">
                                                <TextField
                                                    label={"$Tip"}
                                                    name={"Tip"}
                                                    type="text"
                                                    value={tip}
                                                    onChange={(e) => setTip(e.target.value)}
                                                />
                                                <Button
                                                    block
                                                    size="lg"
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    disabled={!validateTipForm()}
                                                >
                                                    Send
                                                </Button>
                                            </FormGroup>
                                        </form>
                                    )}
                                </div>
                            </>
                        )}
                    </ButtonGroup>
                    <ButtonGroup>
                        {isAuthenticated && post.userId === userInfo.id && (
                            <Button onClick={handleEditButton}>
                                <BsPencilSquare/>
                            </Button>
                        )}
                    </ButtonGroup>
                </Toolbar>
                <p>
                    <MUILink
                        component={Link}
                        to={"/" + (post.username ? post.username : post.userId)}
                        //onClick={(e) => gotoProfile(post.username, post.userId, e)}
                    >
                        {post.username ? post.username : "no username set"}
                    </MUILink>
                    <span className="caption">{addLinks(post.content)}</span>
                </p>

                {comments &&
                    comments.map((comment) => (
                        <p key={"comment-" + comment.commentId}>
                            <MUILink
                                component={Link}
                                to={"/" + (comment.username ? comment.username : comment.userId)}
                                //onClick={(e) => gotoProfile(post.username, post.userId, e)}
                            >
                                {comment.username ? comment.username : "no username set"}
                            </MUILink>
                            <span className="caption"> {addLinks(comment.comment)}</span>
                        </p>
                    ))}

                {isAuthenticated && (
                    <form onSubmit={handleCommentSubmit}>
                        <FormGroup controlId="comment">
                            <TextField
                                label="Add Comment"
                                name="Comment"
                                multiline
                                minRows={4}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <Button
                                block
                                size="lg"
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateCommentForm()}
                            >
                                Save Comment
                            </Button>
                        </FormGroup>
                    </form>
                )}
                {renderDebug(post)}
            </Stack>
        );
    }

    function renderDebug(post) {
        return (
            <div>
                <p>INFO</p>
                {post.groupId && <p>Group: {post.groupId}</p>}
                <div>
                    <p>
                        {/*Tags: {getHashTagLinks(post.content)}<br/>*/}
                        Labels: {getDetectedLabelsString(post)} <br/>
                        Mod Labels: {getDetectedModLabelsString(post)} <br/>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <Container>
            {post && isEditing ? renderEditing(post) : post ? renderView(post) : ""}
        </Container>
    );
}
